import { Box, Text, Image, Stack, Divider, Hide } from "@chakra-ui/react"
import { CloudImages } from "lib/cosmic/images"

const CompanyCards = [
  {
    title: "Pharma",
    icon: CloudImages.TRUSTED_BY_PHARMA_ICON,
  },
  {
    title: "Analytics",
    icon: CloudImages.TRUSTED_BY_ANALYTICS_ICON,
  },
  {
    title: "LLM",
    icon: CloudImages.TRUSTED_BY_LLM_ICON,
  },
  {
    title: "Data Aggregation",
    icon: CloudImages.TRUSTED_BY_DATA_AGGREGATION_ICON,
  },
]

const TrustedBy = () => {
  return (
    <Box backgroundColor="#F3F3FF">
      <Stack
        direction={["column", "column", "row"]}
        spacing={["36px", "36px", "88px"]}
        width={["100%", "100%", "90%"]}
        maxWidth="var(--website-width-max)"
        mx="auto"
        py="40px"
        alignItems={["stretch", "stretch", "center"]}
        px={["40px", "40px", "0px"]}
      >
        <Text color="var(--website-color-p-on-light)" fontWeight="700" fontSize="var(--website-font-size-p)" maxWidth={["none", "180px"]} width="100%" textAlign="left" marginBottom={["12px", "0px"]}>
          Trusted by Top Companies in...
        </Text>

        {CompanyCards.map((card, index) => {
          return (
            <Stack direction={["column", "column", "row"]} alignItems="center" key={index} spacing={["16px", "16px", "12px"]}>
              <Image width="36px" src={card.icon} alt={`${card.title} Icon`} />
              <Text fontSize="var(--website-font-size-p)" color="#50536C" fontWeight="700">
                {card.title}
              </Text>
              <Hide above="md">{index < CompanyCards.length - 1 && <Divider marginTop="12px" borderColor="#C5C5FF" width="216px" />}</Hide>
            </Stack>
          )
        })}
      </Stack>
    </Box>
  )
}

export default TrustedBy
