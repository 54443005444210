import { Stack, Show } from "@chakra-ui/react"

import CapabilitiesMobile from "./CapabilitiesMobile"

const CapabilitiesSection = () => {
  return (
    <Show below="md">
      <Stack margin="40px auto" width={["80%", "70%", "90%"]} maxWidth="var(--website-width-max)" alignItems="baseline" direction={["column", "column", "row"]} spacing={["16px", "24px", "64px"]}>
        <CapabilitiesMobile />
      </Stack>
    </Show>
  )
}

export default CapabilitiesSection
