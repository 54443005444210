import { Box, Text, Image, Hide, Flex } from "@chakra-ui/react"
import { CloudImages } from "lib/cosmic/images"

const LeverageSection = () => {
  return (
    <Hide below="md">
      <Box backgroundColor="#FBFCFF" marginBottom="32px">
        <Box maxWidth="var(--website-width-max)" width="90%" margin="auto" paddingTop="94px" paddingBottom="48px">
          <Flex margin="0 auto" flexDirection="column" alignItems="center" maxWidth="var(--website-width-max)" pb={20}>
            <Text align="center" fontSize="var(--website-font-size-h1)" fontWeight="400" width="90%" lineHeight="1.2" margin="auto" marginBottom="32px" color="var(--website-color-h1-on-light)">
              Leverage Regulated Data and Capture All Points in a Customer Journey
            </Text>
            <Text textAlign="center" color="var(--website-color-p-on-light)" width="70%" fontSize="20px" mb="32px">
              With flexible deployment options, Integral securely processes regulated data across customer touchpoints, enabling companies to derive actionable insights while maintaining privacy
              compliance.
            </Text>
            <Image src={CloudImages.FRAGMENTED_DATA_DIAGRAM} alt="Fragmented Data Diagram" width="100%" height="auto" />
          </Flex>
        </Box>
      </Box>
    </Hide>
  )
}

export default LeverageSection
