import { Box, Image, Text, VStack } from "@chakra-ui/react"
import { CAPABILITIES } from "src/components/Website/CapabilitiesDesktop"

const CapabilitiesMobile = () => {
  return (
    <>
      <Text
        fontSize={["var(--website-font-size-h1-mobile)", "var(--website-font-size-h1-mobile)", "var(--website-font-size-h1)"]}
        textAlign="center"
        lineHeight="102%"
        maxWidth="800px"
        marginBottom="24px"
      >
        Capabilities That Retain Data Fidelity for Your Use Cases
      </Text>
      {CAPABILITIES.map((capability, index) => (
        <Box key={index} background="linear-gradient(180deg, #0F1432 0%, #191445 100%)" border="0.575px solid rgba(99, 37, 227, 0.18);" borderRadius="5.748px" position="relative" marginBottom="20px">
          <Box
            background="white"
            position="absolute"
            left="50%"
            top="-20px"
            width="40px"
            height="40px"
            transform="translateX(-50%)"
            backgroundColor="#101533"
            color="#fff"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight="700"
            borderRadius="50%"
          >
            {index + 1}
          </Box>
          <VStack alignItems="center" textAlign="left" my="24px" mx="40px">
            <Box>
              <Image src={capability.icon} width="54px" height="54px" borderRadius="50%" alt="Capability icon" />
            </Box>
            <Text color="var(--website-color-h1)" marginTop="8px" fontWeight="700">
              {capability.title}
            </Text>
            <Text color="var(--website-color-p)" opacity="0.8" fontWeight="400" textAlign="center">
              {capability.description}
            </Text>
          </VStack>
        </Box>
      ))}
    </>
  )
}

export default CapabilitiesMobile
